import {withTranslation} from 'react-i18next'
import {
  allPlansPurchased,
  getNotPurchasedPlans,
  getPaidPlanPromoDiscount,
  getPurchasablePlansWithValidityText,
  hasPurchasedPlans,
} from '../../../selectors/paid-plans'
import {getButtonStyle, isHollowButton, isRoundedButton} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import c from '../../classnames.scss'
import {isMember} from '../../../selectors/current-member-details'
import {MembershipOffersOwnProps, MembershipOffersRuntimeProps} from './interfaces'
import {MembershipOffers as Presentation} from './membership-offers'
import s from './membership-offers.scss'

const mapRuntime = (
  {state, actions: {promptLogin, openMembershipPicker}}: DetailsPageAppProps,
  {t}: MembershipOffersOwnProps,
): MembershipOffersRuntimeProps => {
  const buttonStyle = getButtonStyle(state.component.settings)
  const isHollow = isHollowButton(buttonStyle)
  const isRound = isRoundedButton(buttonStyle)

  return {
    plans: getPurchasablePlansWithValidityText(state, t),
    bestOfferDiscountPercent: getPaidPlanPromoDiscount(state),
    hasPurchasedPlans: hasPurchasedPlans(state),
    notPurchasedPlans: getNotPurchasedPlans(state),
    allPlansPurchased: allPlansPurchased(state),
    loggedIn: isMember(state),
    mainActionColorClass: isHollow ? s.colorFromHollowButton : s.colorFromFullButton,
    mainActionBorderClass: isHollow ? s.borderFromHollowButton : s.borderFromFullButton,
    mainActionRadiusClass: isRound ? c.rsvpButtonRoundedBorderRadius : c.rsvpButtonBorderRadius,
    promptLogin,
    openMembershipPicker,
  }
}

export const MembershipOffers = withTranslation()(
  connect<MembershipOffersOwnProps, MembershipOffersRuntimeProps>(mapRuntime)(Presentation),
)
