import {ExperimentsBag} from '@wix/wix-experiments'
import {CommonState} from '../types/state'

const isExperimentEnabled = (experiments: ExperimentsBag, name: string) => experiments[name] === 'true'

export const getExperiments = (state: CommonState) => state.experiments

export const isExperimentNameEnabled = (experiments: ExperimentsBag) =>
  isExperimentEnabled(experiments, 'specs.events.ui.ExperimentName')

export const isAppleWalletDownloadEnabled = (experiments: ExperimentsBag) =>
  isExperimentEnabled(experiments, 'specs.events.ui.AppleWalletDownload')
