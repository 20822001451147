import {isTicketed} from '@wix/wix-events-commons-statics'
import {isMobile, isEditor} from '../../../commons/selectors/environment'
import {getReservationState, getRoute} from '../../selectors/navigation'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {RouterRuntimeProps} from './interfaces'
import {Router as Presentation} from './router'

const mapRuntimeToProps = ({state}: DetailsPageAppProps): RouterRuntimeProps => ({
  route: getRoute(state),
  reservationState: getReservationState(state),
  mobile: isMobile(state),
  isTicketed: state.event ? isTicketed(state.event) : false,
  isEditor: isEditor(state),
})

export const Router = connect<{}, RouterRuntimeProps>(mapRuntimeToProps)(Presentation)
