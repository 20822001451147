import {withTranslation} from 'react-i18next'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {ModalRuntimeProps} from './interfaces'
import {Modal as Presentation} from './modal'

const mapRuntimeToProps = ({actions: {closeNativeModal}}: DetailsPageAppProps): ModalRuntimeProps => ({
  closeModal: closeNativeModal,
})

export const Modal = connect<{}, ModalRuntimeProps>(mapRuntimeToProps)(withTranslation()(Presentation))
