import {focusElement} from '@wix/wix-events-commons-statics'
import React from 'react'
import {hookToAttributeSelector, DH} from '../../../constants/data-hooks'
import {SuccessActions} from '../common/success-actions'
import {Container} from '../common/container'
import {Subtitle} from '../common/subtitle'
import {Title} from '../common/title'
import {Desktop} from './desktop'
import {Mobile} from './mobile'
import s from './order-success.scss'
import {OrderSuccessProps} from './index'

export const OrderSuccess: React.FC<OrderSuccessProps> = (props) => {
  const {
    t,
    eventTitle,
    dateTbd,
    template,
    orderPending,
    isAssignedTickets,
    order: {firstName, email},
    appleWalletDownloadEnabled,
  } = props
  const registrationCompleteMsg = isAssignedTickets
    ? 'ticketsThankYou.regCompleteWithAssignedTickets'
    : 'ticketsThankYou.regComplete'
  const ticketsSentMsg = isAssignedTickets
    ? 'ticketsThankYou.ticketSentWithAssignedTickets'
    : 'ticketsThankYou.ticketSent'
  const downloadTicketsVisible = appleWalletDownloadEnabled && !template && !orderPending
  const addToCalendarVisible = !dateTbd

  React.useEffect(() => {
    if (!template) {
      props.ticketedThankYouPageLoaded()
    }

    focusElement({selector: hookToAttributeSelector(DH.ORDER_SUCCESS_TITLE)})
  }, [])

  return (
    <Container dataHook={DH.ORDER_SUCCESS}>
      <div className={s.header}>
        <Title className={s.title} text={t('ticketsThankYou.thanks', {firstName})} dataHook={DH.ORDER_SUCCESS_TITLE} />
        <Subtitle
          lines={[t(registrationCompleteMsg, {eventTitle}), t(ticketsSentMsg, {email})]}
          dataHook={DH.ORDER_SUCCESS_SUBTITLE}
          noBottomMargin={!addToCalendarVisible && !downloadTicketsVisible}
        />
        <SuccessActions
          t={t}
          className={s.successActions}
          addToCalendarVisible={addToCalendarVisible}
          downloadTicketsVisible={downloadTicketsVisible}
        />
      </div>
      <div className={s.desktop} data-hook={DH.DESKTOP}>
        <Desktop {...props} />
      </div>
      <div className={s.mobile} data-hook={DH.MOBILE}>
        <Mobile {...props} />
      </div>
    </Container>
  )
}
