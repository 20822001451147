import {ticketDescriptionExpanded} from '../../../../../selectors/tickets-picker'
import {DetailsPageAppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/context'
import {FixedHeightColumn as Presentation} from './fixed-height-column'
import {FixedHeightColumnOwnProps, FixedHeightColumnRuntimeProps} from './interfaces'

const mapRuntime = (
  {state}: DetailsPageAppProps,
  {ticketDefId}: FixedHeightColumnOwnProps,
): FixedHeightColumnRuntimeProps => ({
  expanded: ticketDescriptionExpanded(state, ticketDefId),
})

export const FixedHeightColumn =
  connect<FixedHeightColumnOwnProps, FixedHeightColumnRuntimeProps>(mapRuntime)(Presentation)
