import {hasImage, EventDetailsAlignment} from '@wix/wix-events-commons-statics'
import {getHeaderAlignment, isSquareImage} from '../../../../selectors/settings'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {ClassicLayout as ClassicLayoutPresentation} from './classic-layout'

export interface ClassicHeaderLayoutProps extends ClassicHeaderLayoutRuntimeProps, ClassicHeaderLayoutOwnProps {}

export interface ClassicHeaderLayoutOwnProps {
  onClickRegistrationButton: Function
}

export interface ClassicHeaderLayoutRuntimeProps {
  headerAlignment: EventDetailsAlignment
  isSquareImage: boolean
  imageVisible: boolean
}

const mapRuntime = ({state}: DetailsPageAppProps): ClassicHeaderLayoutRuntimeProps => ({
  headerAlignment: getHeaderAlignment(state.component.settings),
  isSquareImage: isSquareImage(state.component.settings),
  imageVisible: hasImage(state.event),
})

export const ClassicLayout =
  connect<ClassicHeaderLayoutOwnProps, ClassicHeaderLayoutRuntimeProps>(mapRuntime)(ClassicLayoutPresentation)
