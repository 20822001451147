import {withTranslation} from 'react-i18next'
import {resolveButtonTextTranslation, resolveStatusTextTranslation} from '../../selectors/member-rsvp'
import {getButtonStyle} from '../../selectors/settings'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {ChangeRsvp as Presentation} from './change-rsvp'
import {ChangeRsvpRuntimeProps} from './interfaces'

const mapRuntimeToProps = ({
  state,
  actions: {changeMemberRsvp, navigateToMainPage},
}: DetailsPageAppProps): ChangeRsvpRuntimeProps => ({
  buttonStyle: getButtonStyle(state.component.settings),
  rsvpStatusMessageTranslation: resolveStatusTextTranslation(state),
  rsvpStatusButtonTextTranslation: resolveButtonTextTranslation(state),
  changeMemberRsvp,
  navigateToMainPage,
})

export const ChangeRsvp = connect<{}, ChangeRsvpRuntimeProps>(mapRuntimeToProps)(withTranslation()(Presentation))
export * from './interfaces'
