import {withTranslation} from 'react-i18next'
import {connect} from '../../../runtime-context/context'
import {getSelectedPaidPlan} from '../../../../selectors/paid-plans'
import {DetailsPageAppProps} from '../../../app/interfaces'
import {MembershipSelectorInputOwnProps, MembershipSelectorInputRuntimeProps} from './interfaces'
import {MembershipSelectorInput as Presentation} from './membership-selector-input'

const mapRuntime = ({state}: DetailsPageAppProps): MembershipSelectorInputRuntimeProps => ({
  selectedPlan: getSelectedPaidPlan(state),
})

export const MembershipSelectorInput = withTranslation()(
  connect<MembershipSelectorInputOwnProps, MembershipSelectorInputRuntimeProps>(mapRuntime)(Presentation),
)
