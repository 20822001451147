import {getFormMessages} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {TicketsUnavailableButtonOwnProps, TicketsUnavailableButtonRuntimeProps} from './interfaces'
import {TicketsUnavailableButton as Presentation} from './tickets-unavailable-button'

const mapRuntime = ({state}: DetailsPageAppProps): TicketsUnavailableButtonRuntimeProps => ({
  ticketsUnavailableMessages: getFormMessages(state.event).ticketsUnavailable,
})

export const TicketsUnavailableButton =
  connect<TicketsUnavailableButtonOwnProps, TicketsUnavailableButtonRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
