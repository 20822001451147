import React from 'react'
import {focusElement} from '@wix/wix-events-commons-statics'
import {hookToAttributeSelector} from '../../constants/data-hooks'
import {FormStep, CHECKOUT_STEPS} from '../../constants/constants'
import {isStepCompleted, isStepVisible} from '../../selectors/checkout'
import {BuyerDetails} from '../buyer-details'
import {Policies} from '../policies'
import {CheckoutProps} from './interfaces'
import {TicketsDetails} from './tickets-details'
import {PaymentWidget} from './payment/payment-widget'
import {Payment} from './payment'

export class Checkout extends React.Component<CheckoutProps> {
  payment: React.RefObject<PaymentWidget> = React.createRef<PaymentWidget>()

  getSteps = () => {
    const {
      placedOrder,
      assignedTicketsEnabled,
      buyerDetails,
      validPaymentAdded,
      invoice,
      agreedWithPolicies,
      hasPolicies,
      allTicketDetailsValid,
      isTemplate,
      t,
      messages,
    } = this.props

    return CHECKOUT_STEPS.filter((step) =>
      isStepVisible({step, assignedTicketsEnabled, invoice, hasPolicies, isTemplate}),
    ).map((step) => {
      const stepTitle = t(`mobile.checkout.step.${step}`)
      const buyerDetailsTitle = messages?.checkout?.title ?? stepTitle
      const title = step === FormStep.BuyerDetails ? buyerDetailsTitle : stepTitle

      return {
        key: step,
        title,
        completed: isStepCompleted({
          step,
          placedOrder: placedOrder.order,
          assignedTicketsEnabled,
          buyerDetails,
          validPaymentAdded,
          agreedWithPolicies,
          hasPolicies,
          allTicketDetailsValid,
        }),
        renderStep: this.renderStep(step),
        onEdit: this.handleStepEdit,
      }
    })
  }

  handleStepEdit = async (step: FormStep) => {
    const isMobilePayment = this.props.isMobile && step === FormStep.Payment
    const validPaymentAdded = isMobilePayment ? false : this.props.validPaymentAdded

    this.props.editStep(step, validPaymentAdded)

    switch (step) {
      case FormStep.TicketsDetails:
        return this.props.setExpandedTicketIndex(0)
      case FormStep.Payment:
        return this.payment.current.expand()
      default:
        break
    }
  }

  renderStep = (step: string) => {
    const {t, submitCheckoutStep, currentStep} = this.props
    const props = {
      onSubmit: (data: any) => (currentStep === FormStep.Payment ? this.handlePaymentStep() : submitCheckoutStep(data)),
      t,
    }

    switch (step) {
      case FormStep.BuyerDetails:
        return (editing: boolean) => <BuyerDetails editing={editing} {...props} />
      case FormStep.Policies:
        return (editing: boolean) => <Policies editing={editing} {...props} />
      case FormStep.TicketsDetails:
        return (editing: boolean) => <TicketsDetails editing={editing} {...props} />
      case FormStep.Payment:
        return (editing: boolean) => <Payment paymentRef={this.payment} editing={editing} {...props} />
      default:
        return () => null
    }
  }

  handlePaymentStep = async () => {
    const {isMobile, setValidPaymentAdded, paymentMethodSelected} = this.props
    const validPaymentAdded = await this.payment.current.validate()

    if (!validPaymentAdded || isMobile) {
      setValidPaymentAdded(validPaymentAdded)
    }

    if (validPaymentAdded) {
      paymentMethodSelected(this.payment.current.state.selectedPaymentMethodId)
      await this.pay()
    }

    this.props.handleNextStep()
  }

  pay = async () => {
    if (this.payment.current) {
      const status = await this.payment.current.pay()
      // @ts-expect-error // TODO check this line
      if (status.errorCode) {
        this.props.setValidPaymentAdded(false)
        await this.handleStepEdit(FormStep.Payment)
        this.props.isMobile && focusElement({selector: hookToAttributeSelector('pw-payment-error')})
      } else {
        this.props.placeOrderButtonClicked()
      }

      return status
    }
  }

  render() {
    return this.props.children(this.getSteps(), this.props.currentStep, this.pay)
  }
}
