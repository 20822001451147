import {isEditor} from '../../../../commons/selectors/environment'
import {getReservationError} from '../../../selectors/reservation'
import {getText} from '../../../selectors/settings'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {TicketsPickerOwnProps, TicketsPickerRuntimeProps} from './interfaces'
import {TicketsPicker as Presentation} from './tickets-picker'

export const mapRuntime = ({
  state,
  actions: {openCheckoutUnavailable, clearInvoice, clearCheckout},
}: DetailsPageAppProps): TicketsPickerRuntimeProps => ({
  tickets: state.tickets,
  reservationUnavailable: getReservationError(state),
  title: getText('ticketsTitleText', state.component.settings),
  isEditor: isEditor(state),
  openCheckoutUnavailable,
  clearInvoice,
  clearCheckout,
})

export const TicketsPicker = connect<TicketsPickerOwnProps, TicketsPickerRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
