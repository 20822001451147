import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {getText, isSocialShareVisible} from '../../../selectors/settings'
import {SocialShareWithTitleOwnProps, SocialShareWithTitleRuntimeProps} from './interfaces'
import {SocialShareWithTitle as Presentation} from './with-title'

const mapRuntime = ({state}: DetailsPageAppProps): SocialShareWithTitleRuntimeProps => {
  const componentSettings = state.component.settings
  return {
    title: getText('shareTitleText', componentSettings),
    isVisible: isSocialShareVisible(componentSettings),
  }
}

export const SocialShareWithTitle =
  connect<SocialShareWithTitleOwnProps, SocialShareWithTitleRuntimeProps>(mapRuntime)(Presentation)
export * from './interfaces'
