import {getFormMessages} from '@wix/wix-events-commons-statics'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {ClosedRegistrationButtonOwnProps, ClosedRegistrationButtonRuntimeProps} from './interfaces'
import {ClosedRegistrationButton as Presentation} from './closed-registration-button'

export * from './interfaces'

const mapRuntime = ({state}: DetailsPageAppProps): ClosedRegistrationButtonRuntimeProps => ({
  closedRegistrationMessages: getFormMessages(state.event).registrationClosed,
})

export const ClosedRegistrationButton =
  connect<ClosedRegistrationButtonOwnProps, ClosedRegistrationButtonRuntimeProps>(mapRuntime)(Presentation)
