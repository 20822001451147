import classNames from 'classnames'
import React from 'react'
import cl from '../../../classnames.scss'
import s from './subtitle.scss'
import {SubtitleProps} from '.'

export const Subtitle: React.FC<SubtitleProps> = ({dataHook, lines, noBottomMargin}) => (
  <div
    className={classNames(cl.evTextFont, cl.formLabelsColor, s.subtitle, noBottomMargin && s.noBottomMargin)}
    data-hook={dataHook}
  >
    {lines.map((text) => text && <div key={text}>{text}</div>)}
  </div>
)
