import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {getTicketsPdf} from '../../../../selectors/placed-order'
import {getFormButtonStyle} from '../../../../selectors/settings'
import {OrderSuccessActions as Presentation} from './actions'
import {OrderSuccessActionsOwnProps, OrderSuccessActionsRuntimeProps} from './interfaces'

const mapRuntime = ({state, actions: {downloadTickets}}: DetailsPageAppProps): OrderSuccessActionsRuntimeProps => ({
  ticketsPdf: getTicketsPdf(state),
  formButtonStyle: getFormButtonStyle(state.component.settings),
  downloadTickets,
})

export const OrderSuccessActions =
  connect<OrderSuccessActionsOwnProps, OrderSuccessActionsRuntimeProps>(mapRuntime)(Presentation)
