import {getEventTitle, isTicketed, hasOccurrences} from '@wix/wix-events-commons-statics'
import {withTranslation} from 'react-i18next'
import {isAboutSectionVisible, isMapVisible, isSecondRegistrationButtonVisible} from '../../selectors/event'
import {isMembersVisibleWithGuests} from '../../selectors/members'
import {showPaidPlans} from '../../selectors/paid-plans'
import {getContentAlignment, getTexts, isScheduleVisible, isSocialShareVisible} from '../../selectors/settings'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {hasScheduleItems} from '../../selectors/schedule'
import {EventDetails as Presentation} from './event-details'
import {EventDetailsOwnProps, EventDetailsRuntimeProps} from './interfaces'

const mapRuntimeToProps = ({
  state,
  actions: {handleRSVP, detailsPageLoaded},
}: DetailsPageAppProps): EventDetailsRuntimeProps => {
  const event = state.event
  const componentSettings = state.component.settings
  const paidPlansVisible = showPaidPlans(state)
  const membersDividerVisible =
    isAboutSectionVisible(state) || isSecondRegistrationButtonVisible(state) || !paidPlansVisible
  const ticketedEvent = isTicketed(event)
  const scheduleVisible = isScheduleVisible(componentSettings) && hasScheduleItems(state)

  return {
    eventTitle: getEventTitle(event),
    texts: getTexts(componentSettings),
    contentAlignment: getContentAlignment(componentSettings),
    ticketedEvent,
    membersVisible: isMembersVisibleWithGuests(state),
    membersDividerVisible,
    mapVisible: isMapVisible(state),
    aboutSectionVisible: isAboutSectionVisible(state),
    showPaidPlans: paidPlansVisible,
    handleRSVP,
    detailsPageLoaded,
    hasTickets: ticketedEvent && Boolean(state.tickets.length),
    socialShareVisible: isSocialShareVisible(componentSettings),
    scheduleVisible,
    hasRecurringEvents: hasOccurrences(event),
  }
}

export const EventDetails = connect<EventDetailsOwnProps, EventDetailsRuntimeProps>(mapRuntimeToProps)(
  withTranslation()(Presentation),
)
export * from './interfaces'
