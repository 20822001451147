import React from 'react'
import {useTranslation} from 'react-i18next'
import {DH} from '../../../constants/data-hooks'
import {usePageActions, usePageState} from '../../runtime-context/context'
import {SectionButton} from '../section-button'
import s from './recurring-dates-button.scss'

export const RecurringDatesButton = () => {
  const {t} = useTranslation()
  const eventId = usePageState((state) => state).event.id
  const {openRecurringEventsModal} = usePageActions()
  return (
    <SectionButton
      onClick={() => openRecurringEventsModal(eventId)}
      className={s.root}
      dataHook={DH.RECURRING_DATES_BUTTON}
    >
      {t('recurring.selectDatesButton')}
    </SectionButton>
  )
}
