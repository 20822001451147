import React from 'react'
import {isMobile} from '../../../../commons/selectors/environment'
import {getComponentSettings} from '../../../selectors/component'
import {getButtonStyle, isHollowButton, isRoundedButton} from '../../../selectors/settings'
import {usePageState} from '../../runtime-context/context'
import {SecondaryButton} from '../../styled-components/secondary-button'
import {SectionButtonProps} from './interfaces'

export const SectionButton: React.FC<SectionButtonProps> = ({onClick, dataHook, className, children}) => {
  const buttonStyle = getButtonStyle(usePageState(getComponentSettings))
  const hollow = isHollowButton(buttonStyle)
  const rounded = isRoundedButton(buttonStyle)
  const mobile = usePageState(isMobile)

  return (
    <SecondaryButton
      className={className}
      hollowButton={hollow}
      roundedButton={rounded}
      mobile={mobile}
      onClick={onClick}
      dataHook={dataHook}
    >
      {children}
    </SecondaryButton>
  )
}
