import {IHostProps} from '@wix/native-components-infra/dist/src/types/types'
import {DetailsPageAppProps} from '../app/interfaces'
import {connect} from '../runtime-context/context'
import {AppLoaded as AppLoadedUnconnected} from './app-loaded'

export interface AppLoadedProps extends AppLoadedOwnProps {
  appLoaded: () => void
}

export interface AppLoadedOwnProps {
  host: IHostProps
}

const mapRuntimeToProps = ({actions}: DetailsPageAppProps) => ({
  appLoaded: actions.appLoaded,
})

export const AppLoaded = connect<AppLoadedOwnProps>(mapRuntimeToProps)(AppLoadedUnconnected)
