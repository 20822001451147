import {NEW_FORM_FIELD_VALIDATION} from '@wix/wix-events-commons-statics'
import {InputControlType, RsvpStatus} from '@wix/events-types'
import classNames from 'classnames'
import React from 'react'
import {Field, Fields, Form as ReduxForm} from 'redux-form'
import {DH} from '../../constants/data-hooks'
import {RegFormData} from '../../types'
import {FormButton} from '../form-button'
import {Error} from './commons/error'
import {Radio} from './commons/radio'
import s from './form.scss' // these styles should be overwritten by component styles
import {FormProps} from './interfaces'
import {FORM_DESKTOP_COMPONENTS} from './utils'

export class Form extends React.Component<FormProps> {
  componentDidMount() {
    this.props.changeResponse(this.props.waitlistOpen ? RsvpStatus.WAITING : RsvpStatus.YES)
    this.props.resetRsvpError()
  }

  submit = (values: RegFormData) => {
    const {reservationId, response, onFormSubmit} = this.props
    const customData = reservationId ? {reservation: reservationId} : {response}
    const data = {...customData, ...this.getRsvpData(values)}
    return onFormSubmit(data)
  }

  getRsvpData = (values: RegFormData) => {
    const data = {} as RegFormData

    this.getControls().forEach(({name}) => {
      if (name === 'name') {
        data.firstName = values.firstName
        data.lastName = values.lastName
      } else if (name === 'email') {
        data.email = values.email.trim()
      } else if (name === 'additionalGuests') {
        data.additionalGuests = values[name].additionalGuests
        data.guestNames = values[name].guestNames.map((guest) => `${guest.firstName} ${guest.lastName}`)
      } else {
        data[name] = values[name]
      }
    })

    return data
  }

  handleResponsesKeyDown = (event: React.KeyboardEvent) => {
    switch (event.keyCode) {
      case 37: // arrow left
      case 38: // arrow up
      case 39: // arrow right
      case 40: // arrow down
        event.preventDefault()
        return this.changeResponse(this.props.response === RsvpStatus.NO ? RsvpStatus.YES : RsvpStatus.NO)
      default:
        return
    }
  }

  changeResponse = (response: wix.events.rsvp.RsvpStatus) =>
    this.props.changeResponse(response === RsvpStatus.YES && this.props.waitlistOpen ? RsvpStatus.WAITING : response)

  getControls = () => {
    const {controls, hideAdditionalFields, response} = this.props
    return response === RsvpStatus.YES && !hideAdditionalFields
      ? controls
      : controls.filter((control) => control.system)
  }

  getControl = (control: wix.events.form.InputControl) => {
    const {disabledFields, t} = this.props
    const controlType = control.type || InputControlType.INPUT
    const Component = FORM_DESKTOP_COMPONENTS[controlType]
    const props: any = {t, control, disabled: disabledFields?.includes(control.name)}

    if (controlType === InputControlType.NAME) {
      return <Fields names={['firstName', 'lastName']} component={Component} props={props} />
    }

    return (
      <Field
        name={control.name}
        component={Component}
        props={props}
        validate={NEW_FORM_FIELD_VALIDATION[controlType](control)}
      />
    )
  }

  renderResponses = () => {
    const {messages, response} = this.props
    return (
      <div className={s.responses} tabIndex={0} onKeyDown={this.handleResponsesKeyDown}>
        <div className={s.response}>
          <Radio
            name="response"
            dataHook="yes-radio-button"
            value={messages.rsvp.rsvpYesOption}
            checked={response !== RsvpStatus.NO}
            onChange={() => this.changeResponse(RsvpStatus.YES)}
          />
        </div>
        <div className={s.response}>
          <Radio
            name="response"
            dataHook={DH.NO_RADIO_BUTTON}
            value={messages.rsvp.rsvpNoOption}
            checked={response === RsvpStatus.NO}
            onChange={() => this.changeResponse(RsvpStatus.NO)}
          />
        </div>
      </div>
    )
  }

  render() {
    const {
      hideButton,
      messages,
      noResponseEnabled,
      reservationId,
      buttonStyle,
      error,
      handleSubmit,
      t,
      isTicketForm,
      isFullButton,
      isLastTicket,
      hasPolicies,
      isSubmitDisabled,
      ticketed,
      draftEvent,
    } = this.props
    const shouldFormButtonShow = !hideButton && !isTicketForm
    const shouldTicketFormButtonShow = !hideButton && isTicketForm && !isLastTicket
    const formButtonText = ticketed
      ? messages.checkout.submitActionLabel || messages.rsvp.submitActionLabel
      : hasPolicies
      ? t('mobile.checkout.next')
      : messages.rsvp.submitActionLabel

    return (
      <ReduxForm data-hook="form" className={s.form} onSubmit={handleSubmit(this.submit)} noValidate>
        {noResponseEnabled && !reservationId && this.renderResponses()}
        {this.getControls().map((control) => (
          <div key={control.name} data-hook="form-field">
            {this.getControl(control)}
          </div>
        ))}
        {shouldFormButtonShow && (
          <FormButton
            text={formButtonText}
            buttonStyle={buttonStyle}
            type="submit"
            dataHook={DH.FORM_BUTTON}
            disabled={isSubmitDisabled}
            previewTooltipText={draftEvent ? t('draftEventPreviewTooltip') : null}
          />
        )}
        {shouldTicketFormButtonShow && (
          <button className={classNames(s.formButton, {[s.fullButton]: isFullButton})} type="submit">
            {t('mobile.checkout.ticket.nextTicket')}
          </button>
        )}
        <Error className={s.error} error={error} />
      </ReduxForm>
    )
  }
}
