import * as React from 'react'
import classNames from 'classnames'
import {DH} from '../../../../constants/data-hooks'
import c from '../../../classnames.scss'
import {SectionButton} from '../../section-button'
import s from './footer.scss'
import {FooterProps} from '.'

export const Footer = ({total, t, mobile, navigateToSchedulePage}: FooterProps) => {
  const itemsLeft = total - 2
  return (
    <div className={mobile ? s.mobileFooter : s.footer}>
      {itemsLeft >= 1 && (
        <div className={classNames(s.moreItemsText, c.scheduleLocationFont, c.scheduleLocationColor)}>
          {itemsLeft > 1
            ? t('schedule_moreItemsAvailable_plural', {amount: itemsLeft})
            : t('schedule_moreItemsAvailable_singular')}
        </div>
      )}
      <SectionButton
        onClick={() => navigateToSchedulePage()}
        dataHook={DH.SCHEDULE_SEE_ALL}
      >{t('schedule_seeAll')}
      </SectionButton>
    </div>
  )
}
